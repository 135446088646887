<template>
  <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="23" height="24" fill="white" />
    <path
      d="M4.30431 19.499C5.82301 19.499 7.05417 18.2678 7.05417 16.7491C7.05417 15.2304 5.82301 13.9993 4.30431 13.9993C2.7856 13.9993 1.55444 15.2304 1.55444 16.7491C1.55444 18.2678 2.7856 19.499 4.30431 19.499Z"
      fill="currentColor"
    />
    <path
      d="M0.0108954 23.6751C-0.000536552 23.7126 -0.00301453 23.7522 0.00366105 23.7907C0.0103366 23.8293 0.0259791 23.8658 0.0493286 23.8972C0.072678 23.9286 0.103082 23.9541 0.13809 23.9717C0.173099 23.9892 0.211734 23.9982 0.250883 23.9981H8.35848C8.39754 23.9981 8.43606 23.9889 8.47094 23.9713C8.50582 23.9537 8.5361 23.9282 8.55933 23.8968C8.58257 23.8654 8.59812 23.829 8.60474 23.7905C8.61137 23.752 8.60888 23.7125 8.59747 23.6751C8.31618 22.7558 7.74724 21.9511 6.97438 21.3793C6.20152 20.8076 5.26554 20.499 4.30418 20.499C3.34282 20.499 2.40685 20.8076 1.63398 21.3793C0.861121 21.9511 0.292186 22.7558 0.0108954 23.6751V23.6751Z"
      fill="currentColor"
    />
    <path
      d="M18.5441 19.499C20.0628 19.499 21.2939 18.2678 21.2939 16.7491C21.2939 15.2304 20.0628 13.9993 18.5441 13.9993C17.0253 13.9993 15.7942 15.2304 15.7942 16.7491C15.7942 18.2678 17.0253 19.499 18.5441 19.499Z"
      fill="currentColor"
    />
    <path
      d="M22.8 23.898C22.8231 23.8667 22.8386 23.8305 22.8452 23.7921C22.8518 23.7537 22.8493 23.7143 22.8379 23.6771C22.5567 22.7578 21.9877 21.953 21.2149 21.3813C20.442 20.8096 19.506 20.501 18.5447 20.501C17.5833 20.501 16.6473 20.8096 15.8745 21.3813C15.1016 21.953 14.5327 22.7578 14.2514 23.6771C14.24 23.7144 14.2375 23.7539 14.2441 23.7924C14.2507 23.8309 14.2663 23.8673 14.2895 23.8987C14.3127 23.9302 14.343 23.9557 14.3779 23.9733C14.4128 23.9909 14.4513 24 14.4904 24H22.599C22.6382 24.0002 22.6768 23.991 22.7118 23.9733C22.7467 23.9556 22.7769 23.9298 22.8 23.898V23.898Z"
      fill="currentColor"
    />
    <path
      d="M2.9248 1.49993V10.4995C2.9248 10.8973 3.08283 11.2788 3.36412 11.5601C3.64541 11.8414 4.02692 11.9994 4.42473 11.9994H10.6744C10.7407 11.9994 10.8043 12.0257 10.8512 12.0726C10.8981 12.1195 10.9244 12.1831 10.9244 12.2494V14.4993C10.9244 14.5982 10.9538 14.6949 11.0088 14.7772C11.0638 14.8594 11.142 14.9235 11.2334 14.9613C11.2939 14.9865 11.3588 14.9994 11.4244 14.9993C11.4901 14.9994 11.5552 14.9865 11.6159 14.9615C11.6766 14.9364 11.7318 14.8997 11.7784 14.8533L14.5582 12.0694C14.6056 12.0235 14.6693 11.9984 14.7352 11.9994H18.424C18.8218 11.9994 19.2033 11.8414 19.4846 11.5601C19.7659 11.2788 19.924 10.8973 19.924 10.4995V1.49993C19.924 1.10212 19.7659 0.720608 19.4846 0.439318C19.2033 0.158027 18.8218 0 18.424 0L4.42473 0C4.02692 0 3.64541 0.158027 3.36412 0.439318C3.08283 0.720608 2.9248 1.10212 2.9248 1.49993Z"
      fill="currentColor"
    />
  </svg>
</template>
